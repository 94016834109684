/* Refers to edited text */
p {
  /* Wraps even very long words */
  overflow-wrap: anywhere;
}

.ql-container {
  height: auto !important;
  width: 100% !important;
}

.ql-container.ql-snow {
  border: none !important;
}
.ql-toolbar.ql-snow {
  border: 0px !important;
  border-bottom: 1px solid #dbdbdb !important;
}
.ql-toolbar.q-snow + .ql-container.ql-snow {
  border: 0px !important;
}
