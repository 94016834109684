:root {
    --mainTextAndDeclinedEvents: #000000;
    --calendarEventText: #ffffff;
    --linesAndIcons: #d5d5d5;
    --monthsOnMobileDropdown: #f1f1f1;
    --primaryAndMainCalendar: #51c1db;
    --secondaryCalendar: #315df2;
    --thirdCalendar: #078351;
    --timeLine: #36d2ac;
    --declinedEvent: #636671;
    --alertWarningDeclined: #ba7122;
    --headerBackground: #ffffff;
    --calendarColour4: #5fdb58;
    --calendarColour5: #49a643;
    --calendarColour6: #54d3b3;
    --calendarColour7: #648fdc;
    --calendarColour8: #a3cc3a;
    --calendarColour9: #9fce7b;
    --calendarColour10: #779138;
  }
  
  .rbc-toolbar{
    padding: 0 20px;
    justify-content: center;
  }

  .rbc-btn-group:last-child{
    display: none;
  }

  .rbc-btn-group > button{
    color: transparent;
    border-radius: 50%!important;
    height: 32px;
    width: 32px;
    position: relative;
    margin: 0 130px!important;
    cursor: pointer;
  }

  .rbc-btn-group > button:first-child{
    margin: 0!important;
    margin-right:20px!important;
    right: 0;
    width: 150px;
    position: absolute;
    border: 1px solid #DBDBDB;
    border-radius: 6px!important;
  }


  .rbc-btn-group > button:first-child:after{
    color: #171717;
    content: 'Show Today';
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .rbc-btn-group > button:hover, .rbc-btn-group > button:active, .rbc-btn-group > button:focus{
    color: transparent!important;
  }

  .rbc-btn-group > button::after{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #BDBDBD;
    font-weight: 700;
  }

  .rbc-btn-group > button:nth-child(2)::after{
    content: '<';
  }
  
  .rbc-btn-group > button:last-child::after{
    content: '>';
  }

  .rbc-toolbar-label{
    font-weight: 600;
    font-size:18px;
    line-height: 32px;
    font-family: 'Assistant';
    position: absolute;
  }

  .rbc-button-link{
    font-size:22px;
    font-family: 'Assistant';
    cursor: default;
  }
  .rbc-header{
    display: flex;
    align-items: center;
    border-bottom: none;
  }
  .rbc-header.rbc-today{
    background: #17ABCC;
    border-radius: 8px;
    color: #FFFFFF;
    margin: 4px;
  }

  .rbc-allday-cell{
    display: none;
  }

  .rbc-time-view .rbc-row{
    height: 100%;
  }

  .rbc-time-view .rbc-row .rbc-header{
    padding: 8px 16px;
    font-family: 'Assistant';
  }

  .rbc-timeslot-group{
    min-height: 80px;
  }

  .rbc-timeslot-group .rbc-label{
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #878787;
  }

  .rbc-event, .rbc-event.rbc-selected{
    background-color: #F5F5F5;
    border: none!important;
    border-left: 3px solid #17ABCC!important;
    color: #171717;
    font-family: 'Assistant';
    min-height: 38px!important;
  }

  .rbc-event .short{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rbc-day-slot .rbc-event{
    flex-flow: nowrap;
    flex-direction: column;
  }
  
  .rbc-event-content{
    display:flex;
  }
  
  .rbc-event-label{ 
    display: none;
  }

  .rbc-event-timestamp{
    color: #4F4F4F;
    font-size: 12px;
    font-weight: 500;
  }

  .rbc-event-content h5{
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    align-self: center;
  }

  .rbc-event-content p.rbc-event-desc{
    font-size: 14px;
  }

  .rbc-time-content{
    overflow-x: hidden;
  }

  .rbc-time-header-gutter{
    background-color: #FCFCFC;
  }

  .rbc-time-gutter{
    z-index: 9999;
    background-color: #FCFCFC;
  }

  .rbc-current-time-indicator{
    position: absolute;
    z-index: 999;
    left: -200vh;
    right: -200vh;
    height: 3px;
    background-color: rgb(0, 0, 0);
    width: 500vh;
  }