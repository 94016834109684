:root {
  --sendbird-light-primary-300: #344054 !important;
  --sendbird-light-primary-400: #344054 !important;
}

.sendbird-text-message-item-body.incoming {
  border-radius: 24px 24px 24px 0px;
}

.sendbird-text-message-item-body.outgoing {
  border-radius: 24px 24px 0px 24px;
}

.gif-player {
  width: 100%;
  height: 376px;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

.MuiSwitch-colorPrimary.Mui-disabled.Mui-checked {
  color: #202535;
}

.MuiSwitch-colorPrimary.Mui-disabled.Mui-checked+.MuiSwitch-track {
  background-color: #202535;
}

#font-picker {
  border: 0.0625rem;
  width: 100%;
}

#font-picker .dropdown-button {
  background-color: white;
  height: 50px;
}

@font-face {
  font-family: 'Geomanist-Bold';
  src: url('./fonts/Geomanist-Bold.otf');
}

@font-face {
  font-family: 'Geomanist-Regular';
  src: url('./fonts/Geomanist-Regular.otf');
}

@font-face {
  font-family: 'Geomanist-Medium';
  src: url('./fonts/Geomanist-Medium.otf');
}

@font-face {
  font-family: 'Silka';
  src: url('./fonts/Silka-Regular.otf');
}

@font-face {
  font-family: 'SilkaMedium';
  src: url('./fonts/Silka-Medium.otf');
}

@font-face {
  font-family: 'SilkaBold';
  src: url('./fonts/Silka-Bold.otf');
}

@font-face {
  font-family: 'Assistant';
  src: url('./fonts/Assistant-Regular.otf');
}

@font-face {
  font-family: 'AssistantMedium';
  src: url('./fonts/Assistant-Medium.otf');
}

@font-face {
  font-family: 'AssistantBold';
  src: url('./fonts/Assistant-Bold.otf');
}

/* EXPERIMENTAL */
/* knock 2px off for small screens - use rem for fonts and spaces */
/* html {
  font-size: 14px;
}

@media screen and (min-width: 1600px) {
  html {
    font-size: 16px;
  }
} */

/* 16px is default sizing */
html {
  font-size: 16px;
}