.react-calendar {
  width: 367px;
  max-width: 100%;
  background: #FFFFFF;
  border: 1px solid #F5F5F5;
  font-family: 'Assistant', Arial;
  line-height: 1.125em;
  box-sizing: border-box;
  box-shadow: 4px 0px 16px rgba(23, 23, 23, 0.06);
  border-radius: 4px;
  padding: 22px;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 24px;
  height: auto;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation__label{
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: end;
  order: -1;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #667085;
}
.react-calendar__month-view__weekdays__weekday abbr{
  text-decoration: none;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__tile{
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.react-calendar__month-view__days__day, .react-calendar__month-view__days__day--weekend {
  color: #475467;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #98A2B3;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  border-radius: 4px;
  width: 48px;
  height: 48px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #e3f5f9;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #e3f5f9;
}
.react-calendar__tile--hasActive {
  background: #009DC1;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #009DC1;
}
.react-calendar__tile--active {
  background: #009DC1;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #009DC1;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
.react-calendar__navigation__prev-button, .react-calendar__navigation__next-button, .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button{
  font-size: 25px;
  color: #344054;
}